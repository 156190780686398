import React from 'react';

import { useWindowWidth } from "../../breakpoints";
import { SizeSmallIconNo } from "../../components/SizeSmallIconNo";
import "./style.css";

export const Element = (): JSX.Element => {
  const screenWidth = useWindowWidth();

  return (
    <div className="element">
      <div
        className="element-px"
        style={{
          minHeight: //"100vh", //"-webkit-calc(100vh - 50px)",
            screenWidth < 834
              ? "1908px"
              : screenWidth >= 834 && screenWidth < 1200
              ? "1397px"
              : screenWidth >= 1200 && screenWidth < 1512
              ? "1553px"
              : screenWidth >= 1512
              ? "1635px"
              : undefined,
          overflow: screenWidth >= 1512 ? "hidden" : undefined,
          width:
            screenWidth < 834
              ? "390px"
              : screenWidth >= 834 && screenWidth < 1200
              ? "834px"
              : screenWidth >= 1200 && screenWidth < 1512
              ? "1200px"
              : screenWidth >= 1512
              ? "1512px"
              : undefined,
        }}
      >
        <div
          className="frame"
          style={{
            left:
              (screenWidth >= 1200 && screenWidth < 1512) || (screenWidth >= 834 && screenWidth < 1200)
                ? "42px"
                : screenWidth >= 1512
                ? "32px"
                : screenWidth < 834
                ? "16px"
                : undefined,
            top:
              (screenWidth >= 1200 && screenWidth < 1512) ||
              (screenWidth >= 834 && screenWidth < 1200) ||
              screenWidth < 834
                ? "37px"
                : screenWidth >= 1512
                ? "40px"
                : undefined,
          }}
        >
          <img
            className="img"
            alt="Frame"
            src={
              screenWidth >= 1200 && screenWidth < 1512
                ? "/img/frame-21095-3.svg"
                : screenWidth >= 1512 || (screenWidth >= 834 && screenWidth < 1200)
                ? "/img/frame-21095.svg"
                : screenWidth < 834
                ? "/img/frame-21095-1.svg"
                : undefined
            }
          />
          <SizeSmallIconNo
            className="size-small-icon-no-text-yes-type-secondary-hover-no-disabled-no"
            divClassName="size-small-icon-no-instance"
            text="Coming soon!"
          />
        </div>
        {(screenWidth >= 1512 || (screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834) && (
          <div
            className="div"
            style={{
              alignItems:
                (screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834
                  ? "center"
                  : screenWidth >= 1512
                  ? "flex-start"
                  : undefined,
              left:
                screenWidth < 834
                  ? "53px"
                  : screenWidth >= 834 && screenWidth < 1200
                  ? "223px"
                  : screenWidth >= 1512
                  ? "190px"
                  : undefined,
              top:
                screenWidth < 834
                  ? "124px"
                  : screenWidth >= 834 && screenWidth < 1200
                  ? "157px"
                  : screenWidth >= 1512
                  ? "253px"
                  : undefined,
              width:
                screenWidth < 834
                  ? "287px"
                  : screenWidth >= 834 && screenWidth < 1200
                  ? "388px"
                  : screenWidth >= 1512
                  ? "462px"
                  : undefined,
            }}
          >
            <img
              className="untitled-artwork"
              style={{
                minHeight:
                  (screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834
                    ? "129px"
                    : screenWidth >= 1512
                    ? "64px"
                    : undefined,
                width:
                  (screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834
                    ? "131px"
                    : screenWidth >= 1512
                    ? "64px"
                    : undefined,
              }}
              alt="Untitled artwork"
              src={
                screenWidth < 834
                  ? "/img/untitled-artwork-3-1.svg"
                  : screenWidth >= 834 && screenWidth < 1200
                  ? "/img/untitled-artwork-3-1-1.svg"
                  : screenWidth >= 1512
                  ? "/img/vector.svg"
                  : undefined
              }
            />
            <div
              className="explore-like-an-expert"
              style={{
                fontSize:
                  screenWidth < 834
                    ? "33px"
                    : screenWidth >= 834 && screenWidth < 1200
                    ? "49px"
                    : screenWidth >= 1512
                    ? "77px"
                    : undefined,
                marginLeft: screenWidth < 834 ? "-0.50px" : undefined,
                marginRight: screenWidth < 834 ? "-0.50px" : undefined,
                textAlign: (screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834 ? "center" : undefined,
                width:
                  (screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834
                    ? "288px"
                    : screenWidth >= 1512
                    ? "443px"
                    : undefined,
              }}
            >Explore like an expert</div>
            <p
              className="text-wrapper"
              style={{
                fontSize:
                  screenWidth < 834
                    ? "14px"
                    : screenWidth >= 1512 || (screenWidth >= 834 && screenWidth < 1200)
                    ? "16px"
                    : undefined,
                textAlign: (screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834 ? "center" : undefined,
              }}
            >
              Exploring a city like an expert requires the ability to seamlessly blend in with the locals while still indulging in the tourist experience. There is no better way to achieve this than through our platform where locals and tourists alike share their journeys to uncovering a city. This dual perspective allows you to appreciate the city’s well-known highlights while also uncovering its hidden treasures, local neighbourhoods, authentic cuisine, all while connecting you with a community whose knowledge combines to give you a genuine travel experience to the heart and soul of the place – its people and culture.
            </p>
          </div>
        )}

        {((screenWidth >= 834 && screenWidth < 1200) || screenWidth < 834) && (
          <div
            className="frame-2"
            style={{
              alignItems:
                screenWidth < 834 ? "center" : screenWidth >= 834 && screenWidth < 1200 ? "flex-start" : undefined,
              flexDirection: screenWidth < 834 ? "column" : undefined,
              gap: screenWidth < 834 ? "64px" : screenWidth >= 834 && screenWidth < 1200 ? "40px" : undefined,
              justifyContent: screenWidth >= 834 && screenWidth < 1200 ? "center" : undefined,
              left: screenWidth < 834 ? "54px" : screenWidth >= 834 && screenWidth < 1200 ? "21px" : undefined,
              top: screenWidth < 834 ? "758px" : screenWidth >= 834 && screenWidth < 1200 ? "904px" : undefined,
              width: screenWidth < 834 ? "287px" : screenWidth >= 834 && screenWidth < 1200 ? "792px" : undefined,
            }}
          >
            <div
              className="frame-3"
              style={{
                alignSelf: screenWidth < 834 ? "stretch" : undefined,
                flex: screenWidth < 834 ? "0 0 auto" : screenWidth >= 834 && screenWidth < 1200 ? "1" : undefined,
                flexGrow: screenWidth >= 834 && screenWidth < 1200 ? "1" : undefined,
                width: screenWidth < 834 ? "100%" : undefined,
              }}
            >
              <div className="img-wrapper">
                <img
                  className="img-2"
                  alt="Map"
                  src={
                    screenWidth < 834
                      ? "/img/map-1.png"
                      : screenWidth >= 834 && screenWidth < 1200
                      ? "/img/map-2.svg"
                      : undefined
                  }
                />
              </div>
              <div className="frame-4">
                <div className="text-wrapper-2">Discover</div>
                <p className="p">
                  Travel should be a joy from start to finish. That is why our app streamlines your research, effortlessly provides real-time updates, organises your endless scrolling and offers you personalised recommendations.
                </p>
              </div>
            </div>
            <div
              className="frame-5"
              style={{
                alignSelf: screenWidth < 834 ? "stretch" : undefined,
                flex: screenWidth < 834 ? "0 0 auto" : screenWidth >= 834 && screenWidth < 1200 ? "1" : undefined,
                flexGrow: screenWidth >= 834 && screenWidth < 1200 ? "1" : undefined,
                width: screenWidth < 834 ? "100%" : undefined,
              }}
            >
              <div className="img-wrapper">
                <img
                  className="img-2"
                  alt="Film"
                  src={
                    screenWidth < 834
                      ? "/img/film-1.png"
                      : screenWidth >= 834 && screenWidth < 1200
                      ? "/img/film-2.svg"
                      : undefined
                  }
                />
              </div>
              <div className="frame-4">
                <div className="text-wrapper-2">Capture</div>
                <p className="p">
                  Turn your travel memories into someone else’s inspiration. By joining our travel community, you can capture the essence of your journeys like never before and interact with others inspired by Wanderlust.
                </p>
              </div>
            </div>
            <div
              className="frame-6"
              style={{
                alignSelf: screenWidth < 834 ? "stretch" : undefined,
                flex: screenWidth < 834 ? "0 0 auto" : screenWidth >= 834 && screenWidth < 1200 ? "1" : undefined,
                flexGrow: screenWidth >= 834 && screenWidth < 1200 ? "1" : undefined,
                width: screenWidth < 834 ? "100%" : undefined,
              }}
            >
              <div className="img-wrapper">
                <img
                  className="img-2"
                  alt="Food"
                  src={
                    screenWidth < 834
                      ? "/img/food-5-1.png"
                      : screenWidth >= 834 && screenWidth < 1200
                      ? "/img/food-5-2.svg"
                      : undefined
                  }
                />
              </div>
              <div className="frame-4">
                <div className="text-wrapper-2">Enjoy</div>
                <p className="p">
                  With Trotter, access a wealth of information, destination guides, local tips, and hotel and flight bookings, all in one place. Make the most of Trotter’s informed partnerships and discounts to save every last penny.
                </p>
              </div>
            </div>
          </div>
        )}

        {screenWidth >= 1200 && screenWidth < 1512 && (
          <>
            <div className="frame-7">
              <img className="vector" alt="Vector" src="/img/vector.svg" />
              <div className="text-wrapper-3">
                Explore like an expert
              </div>
              <p className="text-wrapper-4">
                Exploring a city like an expert requires the ability to seamlessly blend in with the locals while still indulging in the tourist experience. There is no better way to achieve this than through our platform where locals and tourists alike share their journeys to uncovering a city. This dual perspective allows you to appreciate the city’s well-known highlights while also uncovering its hidden treasures, local neighbourhoods, authentic cuisine, all while connecting you with a community whose knowledge combines to give you a genuine travel experience to the heart and soul of the place – its people and culture.
              </p>
            </div>
            <div className="frame-8">
              <div className="frame-9">
                <div className="img-wrapper">
                  <img className="img-2" alt="Map" src="/img/map-3.svg" />
                </div>
                <div className="frame-4">
                  <div className="text-wrapper-2">Discover</div>
                  <p className="p">
                    Travel should be a joy from start to finish. That is why our app streamlines your research, effortlessly provides real-time updates, organises your endless scrolling and offers you personalised recommendations.
                  </p>
                </div>
              </div>
              <div className="frame-9">
                <div className="img-wrapper">
                  <img className="img-2" alt="Film" src="/img/film-3.svg" />
                </div>
                <div className="frame-4">
                  <div className="text-wrapper-2">Capture</div>
                  <p className="p">
                    Turn your travel memories into someone else’s inspiration. By joining our travel community, you can capture the essence of your journeys like never before and interact with others inspired by Wanderlust.
                  </p>
                </div>
              </div>
              <div className="frame-9">
                <div className="img-wrapper">
                  <img className="img-2" alt="Food" src="/img/food-5-3.svg" />
                </div>
                <div className="frame-4">
                  <div className="text-wrapper-2">Enjoy</div>
                  <p className="p">
                    With Trotter, access a wealth of information, destination guides, local tips, and hotel and flight bookings, all in one place. Make the most of Trotter’s informed partnerships and discounts to save every last penny.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        {((screenWidth >= 1200 && screenWidth < 1512) || screenWidth >= 1512) && (
          <img
            className="group"
            style={{
              minHeight: screenWidth >= 1200 && screenWidth < 1512 ? "480px" : screenWidth >= 1512 ? "680px" : undefined,
              left: screenWidth >= 1200 && screenWidth < 1512 ? "609px" : screenWidth >= 1512 ? "668px" : undefined,
              top: screenWidth >= 1200 && screenWidth < 1512 ? "241px" : screenWidth >= 1512 ? "192px" : undefined,
              width: screenWidth >= 1200 && screenWidth < 1512 ? "463px" : screenWidth >= 1512 ? "656px" : undefined,
            }}
            alt="Group"
            src={
              screenWidth >= 1200 && screenWidth < 1512
                ? "/img/group-20992-1.svg"
                : screenWidth >= 1512
                ? "/img/group-20992.svg"
                : undefined
            }
          />
        )}

        {screenWidth >= 1512 && (
          <>
            <div className="frame-10">
              <div className="frame-9">
                <div className="img-wrapper">
                  <img className="img-2" alt="Map" src="/img/map.png" />
                </div>
                <div className="frame-4">
                  <div className="text-wrapper-2">Discover</div>
                  <p className="p">
                    Travel should be a joy from start to finish. That is why our app streamlines your research, effortlessly provides real-time updates, organises your endless scrolling and offers you personalised recommendations.
                  </p>
                </div>
              </div>
              <div className="frame-9">
                <div className="img-wrapper">
                  <img className="img-2" alt="Film" src="/img/film.png" />
                </div>
                <div className="frame-4">
                  <div className="text-wrapper-2">Capture</div>
                  <p className="p">
                    Turn your travel memories into someone else’s inspiration. By joining our travel community, you can capture the essence of your journeys like never before and interact with others inspired by Wanderlust.
                  </p>
                </div>
              </div>
              <div className="frame-9">
                <div className="img-wrapper">
                  <img className="img-2" alt="Food" src="/img/food-5.png" />
                </div>
                <div className="frame-4">
                  <div className="text-wrapper-2">Enjoy</div>
                  <p className="p">
                    With Trotter, access a wealth of information, destination guides, local tips, and hotel and flight bookings, all in one place. Make the most of Trotter’s informed partnerships and discounts to save every last penny.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
