/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  className: any;
  divClassName: any;
  text: string;
}

export const SizeSmallIconNo = ({ className, divClassName, text = "Button" }: Props): JSX.Element => {
  return (
    <div className={`size-small-icon-no ${className}`}>
      <div className={`button ${divClassName}`}>{text}</div>
    </div>
  );
};

SizeSmallIconNo.propTypes = {
  text: PropTypes.string,
};
